/*? FUENTES de GOOGLE FONTS = NUNITO  */
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --background: #ffffff;
  --darkGreen: #00453a;
  --lightGreen: #297b62;
  --buttonGreen: #099b0f;
  --black: #1c1c1c;
  --darkGrey: #797979;
  --lightGrey: #cecece;
  --white: #f5f5f5;

  --primaryFont: "Nunito", sans-serif;
  --secondaryFont: "Poppins", sans-serif;
  --fontColor: #323232;
  --secondaryFontColor: #706e6e;
}

body {
  background-color: var(--background);
  font-family: var(--primaryFont);
  color: var(--fontColor);

  margin: 0;

  overflow-x: hidden;
  overflow-y: scroll;
}
